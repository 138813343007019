import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    message,
    Select,
    Button,
    Space
} from 'antd'
import { canadaProvinces, usStates } from '@data/provinces.data'
import { isCreditCardValid, isExpiryDateValid, isCVCValid } from '@utils/Helper'
import { paymentCreditCard, checkRegisterPromoCode } from '@services/api.user.service'
import { MdCheckCircle } from 'react-icons/md'
import { useSelector } from 'react-redux'
import './Payment.css'
const BASE_PRICE = 99


export default function Payment() {

    const userinfo = useSelector(state => state.user.userinfo)
    const business_id = useSelector(state => state.user.business_id)

    const [formRef] = Form.useForm()
    const [provinceList, setProvinceList] = useState(
        canadaProvinces.map(item => ({ label: item.name, value: item.code })),
    )
    const [country, setCountry] = useState('CA')
    const [price, setPrice] = useState(BASE_PRICE)
    const [isSuccess, setIsSuccess] = useState(false)
    const [promoCode, setPromoCode] = useState('')
    const [note, setNote] = useState('* Your first payment starts DATE = TODAY + 1 MONTH.')

    // const plan = planList.find(item => item.type === planType)

    useEffect(() => {
        if (formRef) {
            setIsSuccess(false)
            formRef.setFieldsValue({
                country: 'CA',
                province: canadaProvinces[0].code,
                planType: 'monthly',
                planAmount: 99,
            })
        }
    }, [formRef])

    const onChangeCountry = value => {
        setCountry(value)
        const dataList = value === 'CA' ? canadaProvinces : usStates
        setProvinceList(
            dataList.map(item => ({ label: item.name, value: item.code })),
        )
        formRef?.setFieldsValue({ province: dataList[0].code })
    }

    const checkPromoCode = async () => {
        console.log('checkPromoCode', promoCode)
        if (!promoCode) {
            message.error('Please enter a promo code')
            return
        }
        try {
            const res = await checkRegisterPromoCode({
                promoCode
            })
            if (res?.status !== 'success') {
                setPrice(BASE_PRICE)
                message.error(res?.message || 'Promo code is invalid')
                return
            }
            message.success('Promo code applied successfully')
            if (promoCode === 'FOUNDER50') {
                setNote('* Your first month is free, then $50/month per storefront.')
            } else if (promoCode === 'FOUNDER3FREE') {
                setNote('* Your first 3 months are free, then $99/month per storefront.')
            }
            setPrice(BASE_PRICE - res?.value)
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Action failed, please try again')
        }
    }

    const checkCardNumber = async (_, cardNumber) => {
        if (!cardNumber) {
            return Promise.resolve()
        }
        // return true
        if (!isCreditCardValid(cardNumber)) {
            throw new Error('Card number is invalid')
        }
    }

    const checkExpireDate = async (_, expireDate) => {
        if (!expireDate) {
            return Promise.resolve()
        }
        if (expireDate.length < 4 || expireDate.length > 5) {
            throw new Error('Expire date is invalid')
        }
        if (!isExpiryDateValid(expireDate)) {
            throw new Error('Expire date is invalid')
        }
    }

    const checkCvv = async (_, cvv) => {
        if (!cvv) {
            return Promise.resolve()
        }
        if (!isCVCValid(cvv)) {
            throw new Error('CVV code is invalid')
        }
    }

    const onFinish = async values => {
        try {
            console.log('onFinish', values)
            await paymentCreditCard({
                ...values,
                planType: 'monthly',
                planAmount: price,
                user_id: userinfo?._id || '',
                business_id: business_id || userinfo?.currentBusiness?._id,
                isCustom: false,
                promoCode
            })
            message.success('Submitted successfully.')
            setIsSuccess(true)
        } catch (err) {
            console.error('onFinish', err)
            message.error('Error: ' + err?.data?.message)
        }
    }

    if (isSuccess) {
        return (
            <div className='flex w-full flex-col items-center justify-center gap-[10px] py-[100px] px-[10px]'>
                <h3 className='text-[35px]'>SUBMITTED SUCCESSFULLY</h3>
                <MdCheckCircle size={90} color='#E4B465' />
            </div>
        )
    }

    return (
        <div className='m-auto w-[960px] p-0 smsize:w-full smsize:p-3'>

            <div className='pb-[100px] text-left'>
                <h2 className='py-[40px] text-[40px] smsize:py-[20px] smsize:text-[25px]'>
                    FOUNDING MEMBER OFFER
                </h2>
                <Form
                    form={formRef}
                    className='flex w-full flex-wrap items-start justify-center gap-[100px] smsize:flex-col smsize:gap-4'
                    onFinish={onFinish}
                    style={{
                        textAlign: 'left',
                        width: '100%',
                    }}
                >
                    <div className='flex-1 smsize:w-full'>
                        <h3 className='mb-2 text-[22px] font-[300]'>Store Information</h3>
                        <Form.Item
                            name='email'
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid email',
                                },
                                {
                                    required: true,
                                    message: 'Please input your email',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='Email'
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name='storeName'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input store name',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='Store Name'
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name='firstName'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your first name',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='First Name'
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name='lastName'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input last name',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='Last Name'
                                allowClear
                            />
                        </Form.Item>
                        <div className='flex items-center gap-2'>
                            <Form.Item name='country' initialValue={'CA'} className='flex-1'>
                                <Select
                                    className='rounded-full border border-black py-[18px]'
                                    placeholder='Country'
                                    // bordered={false}
                                    variant='borderless'
                                    dropdownStyle={{
                                        fontSize: '20px',
                                    }}
                                    onChange={onChangeCountry}
                                    options={[
                                        {
                                            label: 'Canada',
                                            value: 'CA',
                                        },
                                        {
                                            label: 'United States',
                                            value: 'US',
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                className='flex-1'
                                name='postalCode'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input postal code',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input
                                    size='large'
                                    className='rounded-full border-black'
                                    placeholder={`${country === 'CA' ? 'Postal' : 'Zip'} Code`}
                                    allowClear
                                />
                            </Form.Item>
                        </div>
                        <div className='flex items-center gap-2'>
                            <Form.Item name='province' className='flex-1'>
                                <Select
                                    size='large'
                                    // bordered={false}
                                    variant='borderless'
                                    className='rounded-full border border-black py-[18px]'
                                    placeholder={`${country === 'CA' ? 'Select a Province' : 'Select a State'
                                        }`}
                                    style={{ width: '100%' }}
                                    options={provinceList}
                                />
                            </Form.Item>
                            <Form.Item
                                className='flex-1'
                                name='city'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input city',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input
                                    size='large'
                                    className='rounded-full border-black'
                                    placeholder='City'
                                    allowClear
                                />
                            </Form.Item>
                        </div>
                        <Form.Item
                            name='address'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input address',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='Address'
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item name='apt' hasFeedback>
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='Apt, suite, etc. (optional)'
                                allowClear
                            />
                        </Form.Item>
                    </div>
                    <div className='flex-1'>

                        <div className='mb-2'>
                            <h3 className='mb-2 text-[22px] font-[300]'>STOREFRONT PARTNERZ</h3>
                            <p><span className='text-[30px]'>${price}/month</span>{' '}(per storefront)</p>
                        </div>
                        <div className='smsize:flex smsize:flex-col'>
                            <h3 className='mb-2 text-[22px] font-[300]'>Discount
                                <span className='text-[12px] ml-2'>Promo code (optional)</span>
                            </h3>
                            <Form.Item
                                name='promoCode'
                                // label='Promo code (optional)'
                                className='flex smsize:flex-col'
                            >
                                <Space direction="horizontal">
                                    <Input
                                        size='large'
                                        placeholder='Promo code'
                                        allowClear
                                        onChange={(e) => {
                                            setPromoCode(e.target.value)
                                        }}
                                    />
                                    <Button
                                        size='large'
                                        onClick={checkPromoCode}
                                    >
                                        Apply
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                        <h3 className='mb-2 text-[22px] font-[300]'>Card Information</h3>
                        <Form.Item
                            name='cardName'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input card name',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='Name on card'
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name='cardNumber'
                            rules={[
                                {
                                    validator: checkCardNumber,
                                },
                                {
                                    required: true,
                                    message: 'Please input card number',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                size='large'
                                className='rounded-full border-black'
                                placeholder='Card number'
                                suffix={<img src='/images/credit-icon.png' alt='' />}
                                allowClear
                            />
                        </Form.Item>
                        <div className='flex items-center justify-center gap-5'>
                            <Form.Item
                                name='cardExpireDate'
                                rules={[
                                    {
                                        validator: checkExpireDate,
                                    },
                                    {
                                        required: true,
                                        message: 'Please input expiry date',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input
                                    size='large'
                                    className='rounded-full border-black'
                                    placeholder='MM/YY'
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                name='cardCvv'
                                rules={[
                                    {
                                        validator: checkCvv,
                                    },
                                    {
                                        required: true,
                                        message: 'Please input cvv',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input
                                    size='large'
                                    className='rounded-full border-black'
                                    placeholder='CVV'
                                    allowClear
                                />
                            </Form.Item>
                        </div>
                        <div className='mt-3 flex items-center justify-center gap-1'>
                            <p>Powered by</p>
                            <img
                                width={40}
                                src='/images/payment/stripe-logo.webp'
                                alt='stripe'
                            />
                        </div>
                        <button
                            type='submit'
                            // disabled={!isAgree}
                            className='mt-3 flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-[16px] text-white hover:bg-white hover:text-fitzba-bright-gold-static disabled:border-none disabled:bg-[#ccc] disabled:hover:text-white'
                        >
                            FINISH
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
